import React from 'react'
import { Layout } from '../Shared'
import helmetProps from './helmetProps'
// import Hero from './sections/Hero'
import Hero from './sections/Hero/Hero__WithForm'
import Features from './sections/Features'
import FAQ from './sections/FAQ'
import SignupFooter from './sections/SignupFooter'
import Try from './sections/Try'
import './Quest.global.scss'

const Quest = ({ width, images }) => {
  let view = 'desktop'
  if (width < 1150) view = 'tablet'
  if (width < 850) view = 'mobile'
  return (
    <div id="quest-page" className={`full-height ${view}`}>
      <Hero images={images.splash} view={view} />
      <Features images={images.features} view={view} />
      <FAQ view={view} />
      {<SignupFooter view={view} />}
      {
        // disabled until quest is live
        // <Try view={view} />
      }
    </div>
  )
}

export default props => (
  <Layout>
    <Quest {...props} />
  </Layout>
)

export { helmetProps }
